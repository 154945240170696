<script lang="ts">
	// components
	import HomeOverview from "$organisms/HomeOverview.svelte";
	import HomeBillboard from "$organisms/HomeBillboard.svelte";
	import HomeAboutTiles from "$organisms/HomeAbout.svelte";
	import TerminalsMap from "$molecules/TerminalsMap.svelte";

	// types
	import type { PageData } from "./$types";

	// props
	export let data: PageData;

	// variables for head
	const metaKeywords = ["Zenith Energy | Bulk Liquid Storage"];
	const metaDescription =
		"Zenith Energy Terminals offers bulk liquid storage across North America, Europe and Latin America.";
</script>

<template lang="pug">
	HomeBillboard(
		billboardContent!="{ data.homePage.billboard }",
		bulletinContent!="{ data.homePage.bulletin }"
	)
	HomeOverview(overviewContent!="{ data.homePage.homeOverview }")
		.w-full.h-full.text-dark(slot="map")
			TerminalsMap(
				isEmbedded!="{ true }",
				locations!="{ data.locations }"
			)

	HomeAboutTiles(tilesData!="{ data.homePage.aboutTiles }")</template>
