<script lang="ts">
	// import types
	import type { TextToutContent } from "$types/types";

	// import components
	import IconArrowRight from "$atoms/IconArrowRight.svelte";

	// props

	export let toutContent: TextToutContent;
</script>

<template lang="pug">
	.flex.items-center(class="w-full h-full")
		//- inner container
		.bg-accent.bg-opacity-10.px-4.py-8.w-full.h-full.flex.items-center(
			class="sm:px-6 lg:min-h-[250px]"
		)
			div
				//- headline
				.font-medium.text-body.mb-8(
					class="md:text-body md:w-3/4 lg:text-lg xl:leading-[1.33em]"
				) { toutContent.headline }

				//- links
				.text-accent.flex
					+each('toutContent.links as link')
						.mr-4.group
							a.inline-block.mr-2.transition-colors.outline-none(
								class="group-hover:text-white focus:underline underline-offset-4 lg:text-body",
								href!="{ link.url ? link.url : '/' + link.slug }",
								title!="{ link.toolTip }"
							) { link.label }
							span.inline-block.w-3.h-3.fill-accent.transition-all(
								class="group-hover:fill-white group-hover:translate-x-[2px]"
							)
								IconArrowRight</template>
