<script lang="ts">
	// import components
	import IconArrowRight from "$atoms/IconArrowRight.svelte";
	import HomeBillboardBackground from "$atoms/HomeBillboardBackground.svelte";
	import HomeNewsBulletin from "$molecules/HomeNewsBulletin.svelte";

	// import types
	import type { Link } from "$types/types";
	import type { BillboardContent } from "$types/types";

	// props
	export let billboardContent: BillboardContent;
	export let bulletinContent: Link;

	// variables
	const headline = billboardContent.headline;
	const imageAlt = billboardContent.imageAlt ? billboardContent.imageAlt : "";
	const imageXXS = billboardContent.imageXXS ? billboardContent.imageXXS : null;
	const imageXS = billboardContent.imageXS ? billboardContent.imageXS : null;
	const imageSM = billboardContent.imageSM ? billboardContent.imageSM : null;
	const imageMD = billboardContent.imageMD ? billboardContent.imageMD : null;
	const imageLG = billboardContent.imageLG ? billboardContent.imageLG : null;
	const imageXL = billboardContent.imageXL ? billboardContent.imageXL : null;
	const image2XL = billboardContent.image2XL ? billboardContent.image2XL : null;
	const links = billboardContent.links;
	const text = billboardContent.text;
</script>

<template lang="pug">
	section.flex.items-center.inset-0.text-white.relative(
		class="h-screen max-h-[800px] sm:h-[800px] bg-[#4E7BDB]"
	)
		//- background image
		HomeBillboardBackground(
			image2XL!="{ image2XL }",
			imageAlt!="{ imageAlt }",
			imageLG!="{ imageLG }",
			imageMD!="{ imageMD }",
			imageSM!="{ imageSM }",
			imageXL!="{ imageXL }",
			imageXS!="{ imageXS }",
			imageXXS!="{ imageXXS }"
		)

		//- bulletin
		+if('bulletinContent')
			HomeNewsBulletin(bulletinContent!="{ bulletinContent }")

		//- billboard content
		.text-white.p-6.z-10.pr-20(
			class="sm:pr-6 xs:w-[320px] sm:w-[350px] md:w-[380px] lg:w-[38%] xl:w-[40%] sm:pl-12 lg:pl-16 xl:pl-18 xl:mt-12"
		)
			//- headline
			h1.text-xl.font-semibold.mb-4.leading-tight(
				class="sm:text-2xl md:text-3xl lg:text-[2.125rem] lg:leading-none xl:text-4xl"
			) { headline }
			//- text
			p.mb-4.text-md(
				class="leading-[1.33em] sm:text-body lg:text-body lg:leading-[1.33em] xl:text-body xl:mb-3"
			) { text }

			//- links
			.flex.text-white
				+each('links as link')
					.flex.items-center.mr-4.group(class="")
						a.text-base.inline-block.mr-2.transition-colors.outline-none(
							class="group-hover:text-accent focus:underline underline-offset-4 xl:text-sub ",
							draggable="false",
							href!="{ link.url ? link.url : '/' + link.slug }",
							title!="{ link.toolTip ? link.toolTip : null }"
						) { link.label }
						.w-3.h-3.fill-white.outline-none(
							class="group-hover:fill-accent transition-all xl:w-[.8em] xl:h-[.8em] xl:stroke-3 xl:stroke-white group-hover:translate-x-[2px]"
						)
							IconArrowRight</template>
