<script lang="ts">
	// import types
	import type { Link } from "$types/types";

	// props
	export let bulletinContent: Link;
</script>

<template lang="pug">
	.absolute.z-10.top-0.left-0.pb-4.w-full.text-15.hidden.text-neutral-50.items-center.opacity-90(
		class!="pt-20 bg-gradient-to-r from-white/[.04] to-transparent sm:flex page-x-padding lg:text-sm xl:text-xs hover:opacity-100"
	)
		div News:
		a.block.ml-2.outline-none.underline-offset-2(
			class="hover:underline focus:underline",
			href!="{ bulletinContent.url ? bulletinContent.url : '/' + bulletinContent.slug }",
			title!="{ bulletinContent?.title }"
		) { bulletinContent.label }</template>
