<script lang="ts">
	// components
	import PictureStack from "$molecules/PictureStack.svelte";
	import TileHeadline from "$atoms/TileHeadline.svelte";
	import TileLinks from "$atoms/TileLinks.svelte";
	import TileText from "$atoms/TileText.svelte";

	// import types
	import type { Tile } from "$types/types";
	import type { Link } from "$types/types";
	import type { Image } from "$types/types";

	// props
	export let alt = "";
	export let headline = "";
	export let images: Image[];
	export let links: Link[] = [];
	export let text = "";
</script>

<template lang="pug">
	.p-0.rounded-lg.outline-none.mb-4.bg-white.shadow(
		class="focus:ring-dark focus:shadow-xl"
	)
		//- image
		.rounded-t.overflow-hidden.mb-4
			PictureStack(
				alt!="{ alt }",
				imageSets!="{[{ images: images, fallback: true }]}"
			)
		//- content
		.px-4.pb-4.pt-2
			TileHeadline { headline }
			TileText { text }
			TileLinks(links!="{ links }")</template>
