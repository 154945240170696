<script lang="ts">
	// types
	import type { StatsWidgetContent } from "$types/types";

	// component
	import NavItem from "$atoms/NavItem.svelte";
	import type { Link } from "$types/types";

	// props
	export let statsWidgetContent: StatsWidgetContent;

	// variables
	let item: Link;
</script>

<template lang="pug">
	.items-center(class="md:flex w-full h-full")
		//- inner container
		.bg-accent.bg-opacity-10.px-4.py-8.w-full(class="sm:px-6 sm:flex xl:pb-8")
			//- list
			.mb-8(class="sm:w-1/2 xl:mb-0")
				h3.text-accent.font-medium.mb-2(class="lg:text-base") { statsWidgetContent?.listHeading }
				ul
					+each('statsWidgetContent?.listItems as item')
						li(class="lg:text-body xl:text-base xl:leading-snug")
							+if('item.disabled == true')
								span { item.label }
								+else
									a(
										class="outline-none focus:underline hover:underline underline-offset-2 lg:text-lg",
										href!="{ item.href || item.url || '/' + item.slug || '' }",
										title!="{ item.title || '' }"
									) { item.label }

			//- info stats
			div(class="sm:w-1/2")
				+each('statsWidgetContent?.stats as stat')
					.mb-4.items-center(class="sm:block lg:flex lg:mb-6")
						//- number
						.text-4xl.font-extralight.text-accent(
							class="md:text-right sm:text-5xl sm:w-1/3 md:w-full lg:pr-4 lg:w-1/3"
						)
							span.inline-block(class="lg:pr-3") { stat.number.toString() }

						//- caption
						.leading-tight(
							class="w-2/3 md:w-full md:text-right lg:w-2/3 lg:text-left lg:text-body xl:text-base xl:leading-tight"
						) { stat.caption }</template>
