<script lang="ts">
	// components
	import HomeTextTout from "$molecules/HomeTextTout.svelte";
	import HomeStatsWidget from "$molecules/HomeStatsWidget.svelte";
	import HomeTerminalsMap from "$molecules/HomeTerminalsMap.svelte";

	// types
	import type { HomeOverviewContent } from "$types/types";

	// props
	export let overviewContent: HomeOverviewContent;
</script>

<template lang="pug">
	section.bg-primary-dark.text-white.pb-24.pt-8.page-x-padding
		//- top row
		.items-start.mb-8.grid.grid-cols-1(
			class="md:grid-cols-2 md:mb-4 xl:-mb-2 xl:-translate-y-4"
		)
			//- heading & text
			.pr-0.w-full(class="sm:pr-12 md:pr-3")
				h2.text-md.font-medium.mb-4(class="md:mt-8 md:text-lg lg:text-xl xl:text-lg") { overviewContent.heading }
				p(class="lg:text-body xl:text-body xl:max-w-[95%] xl:leading-[1.33]") { overviewContent.text }

			//- map
			.flex.justify-center.items-center.w-full.h-full
				slot(name="map")

		//- bottom row
		.items-center.grid.grid-cols-1(
			class="md:grid-cols-2 gap-4 md:gap-8 lg:gap-14 xl:gap-16"
		)
			.flex(class="md:w-full h-full")
				HomeTextTout(toutContent!="{ overviewContent.textTout }")

			.flex(class="md:w-full h-full")
				HomeStatsWidget(statsWidgetContent!="{ overviewContent.statsWidget }")</template>
