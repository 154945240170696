<script lang="ts">
	// import components
	import IconArrowRight from "$atoms/IconArrowRight.svelte";

	// types
	import type { Link } from "$types/types";

	// props
	export let links: Link[];
	let link: Link;
</script>

<template lang="pug">
	.flex.mb-2.text-primary-dark
		+each('links as link')
			a.group.flex.items-center.opacity-90.cursor-pointer.font-base.relative(
				class="!outline-none hover:opacity-100 transition-opacity xl:text-sub focus:underline underline-offset-4",
				href!="{ link.url ? link.url : link.slug ? '/' + link.slug : '' }",
				title!="{ link.label }"
			)
				.leading-none(class="pr-[4.55px]") { link.label }
				.w-3.h-3.fill-primary-dark.flex.items-center(
					class="group-hover:translate-x-1 translate-y-[0.75px] transition-transform "
				)
					IconArrowRight</template>
