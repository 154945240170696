<script lang="ts">
	// import components
	import ImageTile from "$molecules/ImageTile.svelte";

	// import types
	import type { Tile } from "$types/types";

	// props
	export let tilesData: Tile[];
</script>

<template lang="pug">
	section.page-x-padding.bg-neutral-200.py-20(class!="xl:py-[6.6vmax] xl:px-28")
		.grid.grid-cols-1(
			class="gap-y-6 sm:grid-cols-2 sm:gap-x-2 lg:gap-x-6 xl:gap-y-4 xl:gap-x-10 xl:pb-10"
		)
			+each('tilesData as tileContent')
				ImageTile(
					alt!="{ tileContent.imageAlt }",
					headline!="{ tileContent.headline }",
					images!="{ tileContent.image }",
					links!="{ tileContent.links }",
					text!="{ tileContent.text }"
				)</template>
