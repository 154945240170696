<!--
	@component
	Creates image stack background for Home billboard.
-->
<script lang="ts">
	// import components
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	import PictureStack from "$molecules/PictureStack.svelte";

	// import types
	import type { Breakpoint, Image, ImageSet } from "$types/types";

	// import functions
	import { createImageStack } from "$functions/imageFunctions";

	// props
	export let imageAlt = "";
	export let imageXXS: Image[] = [];
	export let imageXS: Image[] = [];
	export let imageSM: Image[] = [];
	export let imageMD: Image[] = [];
	export let imageLG: Image[] = [];
	export let imageXL: Image[] = [];
	export let image2XL: Image[] = [];

	// settings
	//let breakpoints: Breakpoint[];
	const breakpoints: Breakpoint[] = [
		[imageXXS, 0, 419.99],
		[imageXS, 420, 639.99],
		[imageSM, 640, 767.99],
		[imageMD, 768, 1023.99],
		[imageLG, 1024, 1279.99],
		[imageXL, 1280, 1535.99],
		[image2XL, 1526, null],
	];

	let sets: ImageSet[] = [];
	$: sets = createImageStack(breakpoints);
</script>

<template lang="pug">
	.absolute.inset-0.w-full.h-full(class="bg-[#4E7BDB]")
		PictureStack(
			classes="min-h-full object-cover object-center",
			alt!="{ imageAlt }",
			fallback=1200,
			imageSets!="{ sets }",
			loading="eager",
			pictureClasses="flex w-full h-full "
		)</template>
